import { Timestamp } from 'firebase-admin/firestore'

import { captureError } from './exceptions'

export function withDates(data: object): object {
  return Object.fromEntries(
    Object.entries(data).map(([key, value]) => {
      if (typeof value === 'object' && value?.nanoseconds && value?.seconds) {
        return [key, getDateFromFirebaseTimestamp(value)]
      }
      if (
        !Array.isArray(value) &&
        value !== null &&
        typeof value === 'object'
      ) {
        return [key, withDates(value)]
      }
      return [key, value]
    })
  )
}

export function getDateFromFirebaseTimestamp(
  timestamp: Timestamp | Date | string | undefined,
  collectionName?: string,
  docId?: string
) {
  if (!timestamp) {
    return new Date(0)
  }

  if (typeof timestamp === 'string') {
    captureError('FirebaseDateFormatError', {
      error:
        'Parsed date is a string, it should be a firebase timestamp instead.',
      collectionName,
      docId,
    })
    return new Date(timestamp)
  }

  if (timestamp instanceof Date) {
    return timestamp
  }

  return timestamp.toDate()
}
